// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-city-js": () => import("../src/templates/city.js" /* webpackChunkName: "component---src-templates-city-js" */),
  "component---src-templates-model-js": () => import("../src/templates/model.js" /* webpackChunkName: "component---src-templates-model-js" */),
  "component---src-templates-category-js": () => import("../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-page-js": () => import("../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-sitemap-js": () => import("../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-models-js": () => import("../src/pages/models.js" /* webpackChunkName: "component---src-pages-models-js" */)
}

