module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Topcompanions - High class escorts","short_name":"topcompanions","start_url":"/","background_color":"#FFFFFF","theme_color":"#FCB42F","icon":"src/images/topcompanions/favicon-512x512.svg","display":"standalone"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Oswald"]},"typekit":{"id":"mml5erz"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"206756749"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
